<div class="learning-group-add-anchor" tourAnchor="learningGroupAddDialog"></div>
<section class="dialog">
    <header class="dialog__header _with-border">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close learningGroupAddClose" (click)="close()">close</mat-icon>
    </header>

    <form class="dialog__content" *ngIf="isLoaded && available" [formGroup]="formGroup">
        <div class="name form kit">
            <input matInput
                   class="input"
                   formControlName="name"
                   autocomplete="off"
                   [matAutocomplete]="groups"
                [placeholder]="'Наименование группы'"
            >
            <mat-autocomplete autoActiveFirstOption #groups="matAutocomplete" *ngIf="filtered && filtered.length">
                <mat-option
                    *ngFor="let option of filtered"
                    [value]="option.name"
                    (onSelectionChange)="select(option)"
                    [ngClass]="{'hidden': checkSearchLength()}">
                    {{ option.name }}
                </mat-option>
            </mat-autocomplete>
        </div>

        <div class="colors">
            <ng-container *ngFor="let item of colors, let index = index">
                <label class="color-label" *ngIf="index > 0">
                    <input type="radio" class="color-input" name="color" (change)="colorChange($event)" [value]="item.name">
                    <span [ngStyle]="{'background' : item.color }" class="color-emul">
                    <mat-icon [ngClass]="{'hide' : item.name !== selected}"
                              [ngStyle]="{'color' : '#ffffff'}">done</mat-icon>
                </span>
                </label>
            </ng-container>
        </div>
    </form>

    <footer class="dialog__footer" *ngIf="isLoaded">
        <button type="button"
                *ngIf="tagId"
                mat-flat-button
                class="button _link _red _delete"
                (click)="onDeleteClick()">
            <mat-icon class="icon" [svgIcon]="'radius_delete'"></mat-icon>
            Удалить</button>
        <button type="reset"
                mat-flat-button
                class="button _reset _medium _grey"
                (click)="close()">Отмена</button>
        <button type="button" [disabled]="isButtonsDisabled"
                mat-flat-button
                class="button _save _medium learningGroupAddDialog"
                (click)="submit()">Готово</button>
    </footer>

    <app-spinner class="loader" [isSpinnerOn]="true" *ngIf="!isLoaded"></app-spinner>
</section>

<ng-container *ngIf="isDeleteConfirm">
    <app-confirm title="Удалить группу?"
                 confirmTitle="Удалить"
                 cancelTitle="Отмена"
                 (confirm)="delete()"
                 (cancel)="isDeleteConfirm = false;">
    </app-confirm>
</ng-container>
<div *ngIf="isLearningOpen" class="learning-background-overlay"></div>
