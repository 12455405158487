import {Subject} from 'rxjs';

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {takeUntil} from 'rxjs/operators';

import {GlobalNotificationCenterService} from '../../../../services/global-notification-center.service';
import {CompanyService} from '../../../phonebook/services/company.service';
import {Question} from '../../../questions/models/question';
import {ApiQuestionsService} from '../../../questions/services/api-questions.service';
import {QuestionsService} from '../../../questions/services/questions.service';

@Component({
    selector: 'app-question-card',
    templateUrl: './question-card.component.html',
    styleUrls: ['./question-card.component.scss'],
})
export class QuestionCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public id = null;
    public question: Question = null;
    public selectedId: number = null;
    /** триггер завершения подписок */
    private destroyed = new Subject<void>();
    public isLoaded = false;
    public disabled = false;
    public canSave = false;
    public formGroup: UntypedFormGroup = null;
    readonly SUCCESS = 'Ваш ответ учтен';
    readonly ERROR = 'Выберите вариант ответа';
    public settings = null;
    public slot = null;

    constructor(
        private api: ApiQuestionsService,
        private noti: GlobalNotificationCenterService,
        private service: QuestionsService,
        private companyService: CompanyService,
    ) {}

    ngOnInit(): void {
        this.companyService.$settings
            .pipe(takeUntil(this.destroyed))
            .subscribe((res) => {
                if (res) {
                    this.settings = res;
                }
            });

        this.service.surveyUpdate$
            .pipe(takeUntil(this.destroyed))
            .subscribe((id) => {
                if (id == this.id) {
                    this.getQuestion();
                }
            });
    }

    get mainTitle() {
        if (this.question && this.settings) {
            return this.settings.subdivision_functions === 1
                ? 'Оцените качество и срок выполнения функции'
                : 'Оцените качество и срок выполнения обязательства';
        }
        return ' ';
    }

    get title() {
        if (this.question && this.settings) {
            return this.settings.subdivision_functions === 1
                ? 'Функция'
                : 'Обязательство';
        }
        return ' ';
    }

    onOpenChatClick() {
        this.noti.handleSuccess('Не пыпай');
    }

    onClickAnswer(answer) {
        if (this.disabled) {
            return false;
        }

        this.selectedId = answer.id;
        this.formGroup.controls['answer_id'].setValue(this.selectedId);

        if (answer && answer.points === 0) {
            this.formGroup.controls['is_anonymous'].setValue(false);
            this.formGroup.controls['is_anonymous'].disable();
        } else {
            this.formGroup.controls['is_anonymous'].enable();
        }
        this.canSave = true;
    }

    initForm() {
        this.selectedId = this.question.answerId;
        this.disabled = this.question.disabled;

        this.formGroup = new UntypedFormGroup({
            answer_id: new UntypedFormControl(this.question.answerId),
            comment: new UntypedFormControl(
                this.question.comment ? this.question.comment : '',
            ),
            is_anonymous: new UntypedFormControl(
                this.question.answerId ? !!this.question.isAnonymous : false,
            ),
        });

        if (this.disabled) {
            this.formGroup.controls['comment'].disable();
            this.formGroup.controls['is_anonymous'].disable();
        } else {
            this.formGroup.controls['comment'].enable();
            this.formGroup.controls['is_anonymous'].enable();
        }

        // this.formGroup.valueChanges
        //     .pipe(takeUntil(this.destroyed))
        //     .subscribe(res => {
        //         this.canSave = true;
        //     });
    }

    onSubmit() {
        const data = this.formGroup.getRawValue();
        if (!data['answer_id']) {
            this.noti.handleError(this.ERROR);
            return false;
        }
        this.canSave = false;
        this.api
            .sendResponse(this.id, data)
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (res) => {
                    if (res) {
                        this.question = res;
                        this.formGroup = null;
                        this.initForm();
                        this.canSave = false;
                        // this.noti.handleSuccess(this.SUCCESS);
                        this.isLoaded = true;
                        const item = {
                            id: this.id,
                            answerId: this.question.answerId,
                        };
                        this.service.cardAnsweredId$.next(item);
                        this.service.updateQuestion$.next(this.question);
                    }
                },
                (err) => {
                    this.handleError(err);
                    this.canSave = true;
                    this.isLoaded = true;
                },
            );
    }

    getQuestion() {
        this.isLoaded = false;
        this.canSave = false;
        this.api
            .getQuestionById(this.id)
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (res) => {
                    if (res) {
                        this.question = res;
                        this.slot = this.question.slot;

                        this.formGroup = null;
                        this.initForm();
                        this.isLoaded = true;

                        this.service.cardOpenedId$.next(this.id);
                    }
                },
                (err) => {
                    this.handleError(err);
                    this.isLoaded = true;
                },
            );
    }

    onCloseClick() {
        this.api.cardClose$.next(true);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
        this.service.cardOpenedId$.next(null);
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.id.currentValue) {
            this.getQuestion();
        }
    }

    private handleError(err) {
        this.noti.handleFullError(err);
    }
}
