import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from 'src/app/services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {EmailValidatorValueDirective} from 'src/app/utils/email-validator-value.directive';
import {MAIL_REGEXP} from '../../../../../utils/regexps';

@Component({
    selector: 'app-email-edit',
    templateUrl: './email-edit.component.html',
    styleUrls: ['./email-edit.component.scss']
})
export class EmailEditComponent implements OnInit, OnDestroy {

    public formGroup: UntypedFormGroup = null;
    public isConfirmation = false;
    public isSuccessful = false;
    public isLoaded = false;
    public changedEmail = null;
    public messageError = '';
    public isSubmited = false;
    private destroyed = new Subject<void>();
    readonly HEADER_MODAL_CREATE = 'Добавление электронной почты';
    readonly HEADER_MODAL_EDIT = 'Изменение электронной почты';
    readonly HEADER_MODAL_DELETE = 'Удаление электронной почты';
    readonly EMAIL_TEXT = ' электронной почты';
    readonly BUTTON_MODAL_CREATE = 'Добавить';
    readonly BUTTON_MODAL_EDIT = 'Изменить';
    readonly BUTTON_MODAL_DELETE = 'Удалить';
    readonly MODAL_ERROR_INVALID = 'Вы ввели неверный E-mail';
    readonly MODAL_ERROR_ALREADY_EXIST = 'Некорректный email';

    public EMAIL_REG = MAIL_REGEXP;


    constructor(
        private auth: AuthService,
        private notiService: GlobalNotificationCenterService,
        public dialogRef: MatDialogRef<any>,
        public emailValidator: EmailValidatorValueDirective,
        @Inject(MAT_DIALOG_DATA) public data
    ) {

    }
    get titleModal() {
        return (this.data.flag === 'create' ? this.HEADER_MODAL_CREATE : this.data.flag === 'edit' ? this.HEADER_MODAL_EDIT : this.HEADER_MODAL_DELETE);
    }

    get buttonModal() {
        return this.data.flag === 'create' ?
            this.BUTTON_MODAL_CREATE : this.data.flag === 'edit' ?
                this.BUTTON_MODAL_EDIT : this.BUTTON_MODAL_DELETE;
    }

    get errorModal() {
        return this.formGroup.invalid ? this.MODAL_ERROR_INVALID : this.messageError;
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            email: new UntypedFormControl(this.data.account.account, [this.emailValidator.validate, Validators.required]),
        });
        this.isLoaded = true;
   }

    onSubmit() {
        this.isSubmited = true;
        if (this.formGroup.invalid) {
        } else {
            this.auth.checkEmail( {account: this.formGroup.controls['email'].value})
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.sendEmail();
                }, (err) => {
                    this.notiService.handleFullError(err);
                });
        }

    }

    hasError() {
        const email = this.formGroup.controls['email'];
        if (!this.EMAIL_REG.test(email.value)) {
            this.formGroup.controls['email'].setErrors({incorrect: true});
            } else {
            this.formGroup.controls['email'].setErrors(null);
        }
        return (email.invalid) && (email.dirty || email.touched) && this.isSubmited;
    }

    sendEmail() {
        if (this.data.flag === 'create') {
            this.auth.createInviteEmail({
                user: {id: this.data.userId},
                account : this.formGroup.controls['email'].value
            })
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
               this.isSuccessful = true;
                this.changedEmail = this.formGroup.controls['email'].value;
            }, (err) => {
                this.notiService.handleFullError(err);
            });
        }
        if (this.data.flag === 'edit') {
            if (this.data.account.isVerified) {
                this.auth.updateVerifiedEmail({
                    user: {id: this.data.userId},
                    account: this.data.account.account,
                    new_account: this.formGroup.controls['email'].value
                    })
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.changedEmail = this.formGroup.controls['email'].value;
                    this.isSuccessful = true;
                }, (err) => {
                    this.notiService.handleFullError(err);
                });
            } else {
 this.auth.updateInviteEmail({
                user: {id: this.data.userId},
                account: this.data.account.account,
                new_account: this.formGroup.controls['email'].value
            })
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.changedEmail = this.formGroup.controls['email'].value;
                    this.isSuccessful = true;
                }, (err) => {
                    this.notiService.handleFullError(err);
                });
            }
        }
        if (this.data.flag === 'delete') {
            if (this.data.account.isVerified) {
                this.auth.deleteEmail({userId: this.data.userId, account: this.data.account.account})
                .pipe(takeUntil(this.destroyed))
                .subscribe(res => {
                    this.close('delete');
                }, (err) => {
                    this.notiService.handleFullError(err);
            });
            } else {
                this.auth.deleteInviteEmail({userId: this.data.userId, account: this.data.account.account})
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(res => {
                        this.close('delete');
                    }, (err) => {
                        this.notiService.handleFullError(err);
                });
            }
        }
    }

    close(res = null) {
        if (this.changedEmail) {
            this.dialogRef.close(this.changedEmail);
        } else {
            this.dialogRef.close(res);
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

}
