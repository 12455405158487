import * as Sentry from '@sentry/angular';
import { NotifierModule, NotifierOptions } from 'gramli-angular-notifier';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { AsyncPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
    APP_INITIALIZER,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppDateAdapter, SharedModule } from './modules/shared/shared.module';
import { RoutingModule } from './routing.module';
import { MessagingService } from './services/messaging.service';
import { GlobalErrorHandler } from './utils/global-error-handler';
import { ErrorInterceptor } from './utils/guards/ErrorInterceptor';
import {calendarDb} from './modules/calendar/calendar-db';
import {DBConfig, provideIndexedDb} from 'ngx-indexed-db';

registerLocaleData(localeRu, 'ru');

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'right',
            distance: 12,
        },
    },
};

const dbConfig: DBConfig  = {
    name: 'myradius-db',
    version: 20250304,
    objectStoresMeta: [
        calendarDb
    ]
};

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [Sentry.TraceModule,
        RoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        NotifierModule.withConfig(customNotifierOptions),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        NgChartsModule,
        NgxMaskModule.forRoot(),
        SharedModule,
        OverlayscrollbarsModule], providers: [
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
        { provide: LOCALE_ID, useValue: 'ru' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        MessagingService,
        AsyncPipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
        provideIndexedDb(dbConfig),
    ] })
export class AppModule {}
