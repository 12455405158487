import {JSON, JsonObject, JsonProperty, JsonType} from 'ta-json';
import {Link} from '../../../models/link';
import {File} from '../../../models/file';
import {UserShort} from '../../../models/user';
import {Board} from '../../task-manager/models/board';

@JsonObject()
export class MeetUser {
    @JsonProperty('user_id')
    @JsonType(Number)
    id: number;

    @JsonProperty('attended')
    @JsonType(Boolean)
    attended: boolean;

    @JsonProperty('read')
    @JsonType(Boolean)
    read: boolean;

    @JsonProperty('status')
    @JsonType(String)
    status: string;

    @JsonProperty('user')
    @JsonType(UserShort)
    user: UserShort;
}
@JsonObject()
export class RepeatParam {
    @JsonProperty('TYPE')
    @JsonType(String)
    type: string;

    @JsonProperty('INTERVAL')
    @JsonType(Number)
    interval: number;

    @JsonProperty('BYDAY')
    @JsonType(Number)
    weekdays: number[];

    @JsonProperty('DATEEND')
    @JsonType(String)
    dateEnd: string;

    @JsonProperty('UNTIL')
    @JsonType(String)
    until: string;
}

@JsonObject()
class CalendarSmall {
    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

}

@JsonObject()
export class Meet {
    @JsonProperty('id')
    @JsonType(String)
    id?: string;

    @JsonProperty('encrypted_id')
    @JsonType(String)
    encId?: string;

    @JsonProperty('tasks_options')
    @JsonType(Object)
    tasksOptions?: any;

    @JsonProperty('calendar')
    @JsonType(CalendarSmall)
    calendar?: CalendarSmall;

    calendarId?: number;

    @JsonProperty('title')
    @JsonType(String)
    title?: string;

    @JsonProperty('description')
    @JsonType(String)
    description?: string;

    @JsonProperty('place')
    @JsonType(String)
    place?: string;

    @JsonProperty('founder')
    @JsonType(UserShort)
    founder?: UserShort;

    @JsonProperty('parent_id')
    @JsonType(Number)
    parentId?: number;

    @JsonProperty('start')
    @JsonType(String)
    start?: string;

    @JsonProperty('status')
    @JsonType(String)
    status?: string;

    @JsonProperty('end')
    @JsonType(String)
    end?: string;

    @JsonProperty('is_repeat')
    @JsonType(Boolean)
    isRepeat?: boolean;

    @JsonProperty('is_external')
    @JsonType(Boolean)
    isExternal?: boolean;

    @JsonProperty('repeat_params')
    @JsonType(RepeatParam)
    repeatParams?: RepeatParam;

    @JsonProperty('invited')
    @JsonType(UserShort)
    invited: UserShort[] = [];

    @JsonProperty('files')
    @JsonType(File)
    files?: File[] = [];

    @JsonProperty('declined')
    @JsonType(UserShort)
    declined: UserShort[] = [];

    @JsonProperty('accepted')
    @JsonType(UserShort)
    accepted: UserShort[] = [];

    @JsonProperty('referred_users')
    @JsonType(MeetUser)
    referredUsers?: MeetUser[] = [];

    @JsonProperty('links')
    @JsonType(Link)
    links?: Link[] = [];

    @JsonProperty('messages')
    @JsonType(Number)
    messages?: number;


    @JsonProperty('notify')
    @JsonType(Number)
    notify?: number;

    @JsonProperty('protocol')
    @JsonType(Object)
    protocol: any;

    @JsonProperty('old_start')
    @JsonType(String)
    oldStart?: string;

    @JsonProperty('old_end')
    @JsonType(String)
    oldEnd?: string;

    @JsonProperty('author')
    @JsonType(UserShort)
    author: UserShort;

    loadBoards(str, boardsService) {
        let obj;
        if (typeof str === 'string') {
            obj = JSON.parse(str);
        } else {
            obj = str;
        }
        const boards: Board[] = boardsService.boards$.getValue();

        const boardsResult = [];

        if (!Array.isArray(obj)) {
            obj = [];
        }


        obj.map(b => {
            let srcBoard = boards?.find(bo => bo.id === b.id);

            if (!srcBoard) {
                // @ts-ignore
                srcBoard = b;
            }

            const brd = Object.assign({}, srcBoard);

            if (brd) {
                const tags = [];
                b.tags.map(srcTag => {
                    const findTag = brd?.tags?.find(tag => tag.id === srcTag.id);

                    const exist = tags?.find(tag => tag.id === srcTag.id);
                    if (findTag && !exist) {
                        tags.push(findTag);
                    }
                });

                brd.tags = tags;

                boardsResult.push(brd);
            }
        });

        return boardsResult;
    }
}

