import {Component, Inject, OnInit} from '@angular/core';
import {FiltersService} from '../../services/filters.service';
import {BoardsService} from '../../services/boards.service';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigBoardsService} from '../../services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-task-create-helper',
    templateUrl: './task-create-helper.component.html',
    styleUrls: ['./task-create-helper.component.scss']
})
export class TaskCreateHelperComponent implements OnInit {
    // Модель формы
    public data = {};
    public formGroup: UntypedFormGroup = null;
    public title = 'Добавить следующие параметры при создании задачи?';
    public importance = this.config.IMPORTANCE_TYPE;
    public sharedBoard = false;

    constructor(
        private filtersService: FiltersService,
        private boardsService: BoardsService,
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public dataMore: any,
        public config: ConfigBoardsService,
        public auth: AuthService
    ) {

    }

    static isDialogNeeded(data) {
        if (!data) {
            return false;
        }

        return data['responsible'] ||
            data['performers'].length > 0 ||
            data['observers'].length > 0 ||
            data['importancePriority'].length > 0 ||
            data['sprints'].length > 0 ||
            data['tags'].length > 0 ||
            data['groups'].length > 0;
    }

    static getDataFromFilters(filtersService: FiltersService, config, boardsService, ignoreSprints = false) {
        const data = {
            responsible: null,
            performers: [],
            observers: [],
        }

        const filters = filtersService.tasksFilters$.value;
        if (!filters) {
            return data;
        }

        data.performers = filters.performers
            .map(id => {
                const user = filtersService.getPerformerById(id);
                if (user) {
                    user['selected'] = true;
                    return user;
                }
            }).filter(el => +el?.id !== 0);

        data.responsible = data.performers?.length === 1 ? data.performers[0] : null;

        data.observers = filters.observers
            .map(id => {
                const user = filtersService.getObserversById(id);
                if (user) {
                    user['selected'] = true;
                    return user;
                }
            }).filter(el => +el?.id !== 0);

        if (data['performers']?.length &&
           data['observers']?.length) {
                data['observers'] = data['observers'].filter(el => !data['performers']?.some(p => p.id == el.id));
        }
        data['importancePriority'] = [];
        if (filters && filters.importancePriority && filters.importancePriority.length) {
            data['importancePriority'] = [];
            filters.importancePriority.forEach(el => {
                data['importancePriority'].push(config.getImportanceById(el));
            });
            data['importancePriority'].forEach(s => s['selected'] = false);
            data['importancePriority'][0].selected = true;
        }

        data['groups'] = [];
        if (filters && filters.groups.length) {
            const board = boardsService.boardSelected$.value;
            const groups = board.tags.filter(g => g.taskGroup);
            data['groups'] = filters.groups.map((id, index) => {
                const g = groups.find(el => el.id === id);
                if (g) {
                    g['selected'] = index === 0;
                }
                return g;
            }).filter(el => el && el.id !== 0);

            data['group'] = data['groups'][0];
            data['groups'].forEach(s => s['selected'] = false);
            data['groups'][0]['selected'] = true;
        }

        data['sprints'] = [];
        if (!ignoreSprints) {
            if (filters && filters.sprints.length) {
                data['sprints'] = filters.sprints.map((id, index) => {
                    const s = filtersService.getSprintById(id);
                    s['selected'] = index === 0;

                    return s;
                });

                data['sprint'] = data['sprints'][0];
            }
        }

        data['tags'] = [];
        if (filters && filters.tags.length) {
            data['tags'] = filters.tags.map(id => {
                const tag = filtersService.getTagById(id);
                tag['selected'] = true;
                return tag;
            });

            data['tags'] = data['tags'].filter(el => el.id !== 0);
        }

        return data;
    }

    static prepareData(filtersService, configService, boardsService, dataMore?, ignoreSprints = false) {
        const data = TaskCreateHelperComponent.getDataFromFilters(filtersService, configService, boardsService, ignoreSprints);

        if (dataMore) {
            if (dataMore['performers']) {
                dataMore['performers'].forEach(el => {
                    if (!data['performers']?.some(p => p.id === el.id)) {
                        el['selected'] = true;
                        data['performers'].push(el);
                    }
                });
            }

            if (dataMore['observers']) {
                dataMore['observers'].forEach(el => {
                    if (!data['observers']?.some(p => p.id === el.id)) {
                        el['selected'] = true;
                        data['observers'].push(el);
                    }
                });
            }

            if (dataMore['sprint'] && !data['sprints']?.some(el => el.id === dataMore['sprint']['id'])) {
                data['sprints'].push(dataMore['sprint']);
                dataMore['sprint']['selected']= !data['sprints']?.length;
                data['sprint'] = data['sprints'][0];
            }

            const b = dataMore['boards'].find(el => el.id > 0);
            const group = b?.tags.find(el => el.taskGroup);
            const tags = b?.tags.filter(el => !el.taskGroup);
            if (group && !data['groups']?.some(el => el.id === group['id'])) {
                group['selected'] = !data['groups']?.length;
                data['groups'].push(group);
                data['group'] = data['groups'][0];
            }

            if (tags && tags.length) {
                tags.forEach(t => {
                    if (!data['tags']?.some(el => el.id === t.id)) {
                        t['selected'] = true;
                        data['tags'].push(t);
                    }
                });
            }

            if (dataMore['sprint'] && !data['sprints']?.some(el => el.id === dataMore['sprint']['id'])) {
                dataMore['sprint']['selected'] = !data['sprints']?.length;
                data['sprints'].push(dataMore['sprint']);
            }

            if (dataMore.links.length || dataMore.files.length) {
                data['adds'] = {
                    selected: true,
                    links: dataMore.links,
                    files: dataMore.files,
                };
            }
        }

        return data;
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({});

        if (this.dataMore && this.dataMore['sharedBoard']) {
            this.sharedBoard = true;
            this.title = 'Добавить следующих участников при добавлении доски?';

            this.data['performers'] = this.dataMore.performers.map(el => {
                el['selected'] = true;
                return el;
            });
            this.data['performers'] = this.data['performers'].filter(el => el && el.id && el.id !== 0);

            if (this.dataMore['responsible']) {
                this.dataMore['responsible']['selected'] = true;
                this.data['responsible'] = this.dataMore['responsible'];
                this.data['performers'].unshift(this.dataMore['responsible'])
            }

            this.data['responsible'] = this.dataMore['responsible'] ? this.dataMore['responsible'] : null;

            this.data['observers'] = this.dataMore.observers.map(el => {
                el['selected'] = true;
                return el;
            });
            this.data['observers'] = this.data['observers']?.filter(el => el && el.id && el.id !== 0);

            if (this.data['performers'] && this.data['performers']?.length &&
                this.data['observers']?.length && this.data['observers'] ) {
                this.data['observers'] = this.data['observers']?.filter(el => !this.data['performers']?.some(p => p.id == el.id));
            }
            return;
        }

        this.data['groups'] = this.dataMore['groups'];
        if (this.data && this.data['groups']?.length > 0) {
            this.changeGroup({checked: true}, this.data['groups'][0]);
        }
        this.data['sprints'] = this.dataMore['sprints'];
        this.data['tags'] = this.dataMore['tags'];
        this.data['observers'] = this.dataMore['observers'];
        this.data['performers'] = this.dataMore['performers'];
        this.data['responsible'] = this.dataMore['responsible'];
        this.data['importancePriority'] = this.dataMore['importancePriority'];
    }

    changeSprint(evt, sprint) {
        this.data['sprints'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            sprint['selected'] = true;
            this.data['sprint'] = sprint;
        } else {
            this.data['group'] = null;
        }
    }

    changeImportance(evt, item) {
        this.data['importancePriority'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            item['selected'] = true;
        }
    }

    changeGroup(evt, group) {
        this.data['groups'].forEach(s => s['selected'] = false);
        if (evt.checked) {
            group['selected'] = true;
            this.data['group'] = group;
        } else {
            this.data['group'] = null;
        }
    }

    close() {
        this.dialogRef.close();
    }

    reset() {
        this.close();
    }

    get isSelected() {
        const arr = Object.values(this.data);
        let val = false;

        arr.forEach(el => {
            if (el && el['selected']) {
                val = true;
            }

            if (Array.isArray(el) && el.some(i => i['selected'])) {
                val = true;
            }
        });

        return val;
    }

    onSubmit() {
        if (this.isSelected) {
            this.dialogRef.close(this.data);
        }
    }

    onUsersChange(evt, user) {
        if (!user['selected'] && this.data['responsible'] && this.data['responsible'].id === user.id ) {
            this.data['responsible'] = null;
        }
    }

    onResponsibleClick(evt, user) {
        if (!user['selected']) {
            return false;
        }

        evt.stopPropagation();
        evt.preventDefault();

        this.data['responsible'] = this.data['responsible']?.id ===  user.id ? null: user;
    }

}
