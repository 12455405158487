import {JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';
import {Task} from './task';

export enum ColumnEnum {
    ToDo = -1,
    InProgress = -3,
    Done = -4,
    Canceled = -5,
    Archive = -6,
    OnApprove = -7,
}

@JsonObject()
export class Column {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('color')
    @JsonType(String)
    color?: string;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('tag')
    @JsonType(String)
    tag?: string;

    @JsonProperty('order')
    @JsonType(Number)
    order?: number;

    @JsonProperty('tasks')
    @JsonElementType(Task)
    tasks?: Task[] = [];

    @JsonProperty('parent')
    @JsonElementType(Column)
    parent?: Column = null;
}

/**
 * Маппинг статусов
 */
export const columnColors = Object.freeze([
    {
        parentColor: '_green',
        colors: ['#94C859', '#9ECD68', '#A8D277', '#B1D787', '#BBDC96']
    },
    {
        parentColor: '_blue',
        colors: ['#339EF4', '#46A7F5', '#58B0F6', '#6BB8F7', '#7EC1F8']
    },
    {
        parentColor: '_grey',
        colors: ['#BFBFBF', '#CACACA']
    },
    {
        parentColor: '_cyan',
        colors: ['#15C1D7', '#2BC7DB', '#40CDDF', '#55D2E2', '#6BD8E6']
    },
    {
        parentColor: '_red',
    },

]);
