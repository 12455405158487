import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {NotifierService} from 'gramli-angular-notifier';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    readonly auth: string = environment.authApiUrl;
    readonly authService: string = environment.apiBase + 'platform-authorization/api/v1/';
    readonly authorization: string = environment.authorization;
    readonly paymentsService: string = environment.paymentsService;
    readonly billingService: string = environment.billingService;
    readonly tariffsService: string = environment.tariffsService;
    readonly authorizationInvites: string = environment.authorizationInvites;
    readonly authorizationInvitesComplete: string =  environment.authorizationInvitesComplete;
    readonly sendEmail: string = environment.sendEmail;
    readonly authUpdateEmail = environment.authLoginEmail;
    readonly authEmailInvite = environment.authEmailInvite;
    readonly authEmailAcceptInvite = environment.authEmailAcceptInvite;
    readonly apps: string = environment.apps;
    readonly invites: string = this.authorization + 'invites/info';
    readonly signIn: string = this.authorization + 'sign-in/';
    readonly signOut: string = this.authorization + 'sign-out/';
    readonly signReset: string = this.authorization + 'sign-in-reset/';
    readonly resetComplete: string = this.authorization + 'sign-in-reset-complete/';
    readonly signUpComplete: string = this.authorization + 'sign-up-invite-complete/';
    readonly fileStorageURL: string = environment.fileStorageURL;
    readonly fileStoragePicsUrl = environment.fileStoragePicsUrl;
    readonly fileStorageUsersUrl = environment.fileStorageUsersUrl;
    readonly fileStorageTasksUrl = environment.fileStorageTasksUrl;
    readonly photoAvatarUser: string = environment.photoAvatarUser;
    readonly fcm: string = environment.fcm;
    readonly chatRooms: string = environment.chatRooms;
    readonly chatRoomUsers: string = environment.chatRoomUsers;
    readonly chatRoomNotification: string = environment.chatRoomNotification;
    readonly chatHistory: string = environment.chatHistory;
    readonly sendMessage: string = environment.sendMessage;
    readonly editChatMessage: string = environment.editChatMessage;
    readonly notificationsHistory = environment.notificationsHistory;
    readonly platformLearning:string = environment.platformLearning;
    readonly setNotificationSettings: string = environment.setNotyficationSettings;
    readonly getNotificationSettings: string = environment.getNotyficationSettings;
    /** Запрос каунтеров для задачника */
    readonly getBoardsCounters: string = environment.getBoardsCounters;
    /** API URL получения всех моих кругов, включая те, где я администратор */
    readonly usersCirclesWithAdmin = environment.usersCirclesWithAdmin;
    /** API URL лого орг юнита */
    readonly logoOrgUnitURL: string = environment.logoOrgUnitURL;
    readonly myCircles: string = environment.myCircles;
    readonly users = environment.usersUrl;
    readonly rulesByUserId: string = environment.permissions;
    /** API URL получения смарт-контрактов */
    readonly smartContracts = environment.smartContracts;
    /** API URL для получения данных по конкретной заявке на оплату **/
    readonly paymentRequest = environment.paymentRequest;
    /** API URL для сервиса прав **/
    readonly permissionsBase = environment.permissionsBase;
    /** Урл для новостей портала **/
    readonly chatMembers = environment.chatMembers;
    readonly chatAvailableMembers = environment.chatAvailableMembers;
    readonly addChatMember = environment.addChatMember;
    readonly removeChatMember = environment.removeChatMember;

    readonly checkEmail = environment.checkEmail;

    readonly functionalStructure = environment.functionalStructure;
    readonly structAdmin = environment.structAdmin;
    readonly structAdminLite = environment.structAdminLite;
    readonly structSettings = environment.structSettings;

    readonly structPositions = environment.structPositions;
    readonly structPosition = environment.structPosition;
    readonly structSlot = environment.structSlot;
    readonly structSort = environment.structSort;
    readonly positionFolders = environment.positionGroups;
    readonly positionStructFolder = environment.positionStructFolder;

    readonly accessRights = environment.accessRights;

    readonly setNotificationEmail = environment.setNotifiableEmail;

    readonly companyProfile = environment.companyProfile;
    readonly oldHistory = environment.history;

    readonly motivations = environment.motivations;
    readonly motivationsSettings = environment.motivationsSettings;
    readonly motivationsCustomFields = environment.motivationsCustomFields;
    readonly childrenApplications = environment.apps;

    readonly matrixBaseUrl = environment.matrixBaseUrl;
    readonly matrix = environment.matrix;
    readonly matrixAccess = environment.matrixAccess;
    readonly matrixSetting = environment.matrixSetting;
    readonly matrixSubdivision = environment.matrixSubdivision;

    readonly userHelpers = environment.userHelpers;

    readonly deputy = environment.deputy;
    readonly matrixSmartTasks = environment.matrixSmartTasks;

    readonly functions = environment.functions;
    readonly companySettings = environment.companySettings;
    readonly history = environment.history;

    /** API for Микросервис импортов из других источников */
    readonly importerUrl = environment.importerUrl;

    readonly usersApiUrl: string = environment.usersUrl;
    readonly usersApiInfoUrl: string = environment.usersUrl + 'info/';
    readonly taskManagerTaskUrl = environment.taskManagerTaskUrl;
    readonly apiBase = environment.apiBase;
    readonly workdaySnapshot = environment.workdaySnapshot;
    readonly workdaySnapshotStats = environment.workdaySnapshotStats;

    readonly tz = [
        {name: '(UTC-12:00) IDLW', value: 'Etc/GMT+12'},
        {name: '(UTC-11:00) Самоа', value: 'Pacific/Samoa'},
        {name: '(UTC-10:00) Гаваи', value: 'US/Hawaii'},
        {name: '(UTC-09:00) Аляска', value: 'US/Alaska'},
        {name: '(UTC-08:00) Тихоокеанское время', value: 'US/Pacific'},
        {name: '(UTC-07:00) Аризона', value: 'US/Arizona'},
        {name: '(UTC-06:00) Мехико', value: 'America/Mexico_City'},
        {name: '(UTC-04:00) Сантьяго', value: 'America/Santiago'},
        {name: '(UTC-03:00) Буэнос Айрес', value: 'America/Buenos_Aires'},
        {name: '(UTC-02:00) Стэнли', value: 'Atlantic/Stanley'},
        {name: '(UTC-01:00) Кабо-Верде', value: 'Atlantic/Cape_Verde'},
        {name: '(UTC+00:00) Западноевропейское время', value: 'Europe/London'},
        {name: '(UTC+01:00) Центральноевропейское время', value: 'Europe/Prague'},
        {name: '(UTC+02:00) Калининградская область', value: 'Europe/Kaliningrad'},
        {name: '(UTC+03:00) Москва', value: 'Europe/Moscow'},
        {name: '(UTC+04:00) Самара', value: 'Europe/Samara'},
        {name: '(UTC+05:00) Екатеринбург', value: 'Asia/Yekaterinburg'},
        {name: '(UTC+06:00) Омск', value: 'Asia/Omsk'},
        {name: '(UTC+07:00) Новосибирск', value: 'Asia/Novosibirsk'},
        {name: '(UTC+08:00) Иркутск', value: 'Asia/Irkutsk'},
        {name: '(UTC+09:00) Якутия', value: 'Asia/Yakutsk'},
        {name: '(UTC+10:00) Владивосток', value: 'Asia/Vladivostok'},
        {name: '(UTC+11:00) Магадан', value: 'Asia/Magadan'},
        {name: '(UTC+12:00) Камчатка', value: 'Asia/Kamchatka'}
    ];

    constructor(private notifierService: NotifierService) {
    }

    hasSizes(url) {
        return url.search('large')  >= 0 || url.search('small')  >= 0;
    }

    image(image, entity = 'news', size = 'large', type = 'pics') {
        if (image && image.search('https') >= 0) {
            if (this.hasSizes(image)) {
                return image;
            }
            return image + '/' + size;
        }

        if (image && image.search(/-/gi)) {
            return this.fileStorageURL + `${type}/${entity}/${image}/${size}`;
        }

        return image;
    }

    getUserAvatarByUser(user: any, size = 'small', force: boolean = false) {
        const photo = user.photo ? user.photo : user.userPhoto;
        if (photo && (photo.search('http') >= 0)) {
            // if (this.hasSizes(user.photo)) {
            //     return user.photo
            // }
            //
            // if (user.photo.search('svg')  >= 0) {
            //     return user.photo
            // }

            return photo;
        }

        if (!photo && user.id) {
            return this.photoAvatarUser + '/default/' + size
           // return this.getUserAvatar(user.id, size);
        }

        if (photo && photo.search('user_photo') >= 0) {
            return this.fileStorageURL + 'files/' + photo + '/' + size;
        }

        return  this.fileStorageUsersUrl + photo  + '/' + size;
        // return this.getUserAvatar(user.id, size, force);
    }

    /**
     * Возвращает URL фото логотипа
     *
     * @param photo string
     * @param size
     * @return string
     */
    getLogoSrc(photo, size = null) {
        if (photo && photo.search('https') >= 0) {
            if (size === 'large') {
                const re = /_small/gi;
                photo = photo.replace(re, '_large');
            }
            return photo;
        } else {
            return this.logoOrgUnitURL + '/' + photo + '/small';
        }
    }

    /**
     * обработчик ошибок
     *
     * @param err String
     */
    public handleFullError(err) {
        this.notifierService.notify('error', err && err.error && err.error.message ? err.error.message : err.message);
    }

    /**
     * Возвращает URL фото
     *
     * @params {string} userId
     * @params {string} size
     * @return string
     */
    getUserAvatar(userId, size, force: boolean = false) {
        // let timeStamp = '?' + new Date().getMonth() + new Date().getDate() + new Date().getHours() + new Date().getMinutes();
        // if (force) {
        //     timeStamp = timeStamp + new Date().getTime();
        // }

        // return size ? this.photoAvatarUser + '/' + userId + '/' + size
        //     : this.photoAvatarUser + '/' + userId

        return size ? this.photoAvatarUser + userId + '/' + size
            : this.photoAvatarUser + userId;
        // return size ? this.photoAvatarUser + '/' + userId + '/' + size  + timeStamp
        //     : this.photoAvatarUser + '/' + userId + '/' + '?' + timeStamp
    }


}
