import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Rating} from '../../models/rating';
import {CompanyService} from '../../../phonebook/services/company.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-rating-number',
    templateUrl: './rating-number.component.html',
    styleUrls: ['./rating-number.component.scss']
})
export class RatingNumberComponent implements OnInit, OnDestroy {
    @Input() rating: Rating = null;
    @Input() ratingWithSign: Rating = null;
    @Input() extraWord: string = null;

    @Input() greenFrom: number = null;  
    @Input() orangeFrom: number = null; 
    @Input() orangeTo: number = null;   
    @Input() redTo: number = null; 

    public settings = null;
    private destroyed = new Subject<void>();

    constructor(
        private company: CompanyService) {
    }

    ngOnInit() {
        this.company.$settings
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res) {
                    this.settings = res;
                }
            });
    }

    get value() {
        return this.rating?.value?.toFixed(1);
    }

    get green() {
        const min = this.greenFrom ?? (this.settings?.green_from || 0);
        return min <= (this.ratingWithSign?.value ?? this.rating.value);
    }

    get orange() {
        const min = this.orangeFrom ?? (this.settings?.orange_from || 0);
        const max = this.orangeTo ?? (this.settings?.orange_to || Infinity);
        return min <= (this.ratingWithSign?.value ?? this.rating.value) && max >= (this.ratingWithSign?.value ?? this.rating.value);
    }

    get red() {
        const max = this.redTo ?? (this.settings?.red_to || Infinity);
        return (this.ratingWithSign?.value ?? this.rating.value) <= Number(max);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
