import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Board} from './board';


export class Folder {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('name')
    @JsonType(String)
    name?: string;

    @JsonProperty('boards')
    @JsonElementType(Board)
    boards: Board[]= [];
}
