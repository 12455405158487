import {JsonElementType, JsonObject, JsonProperty, JsonType} from 'ta-json';
import {UserShort} from '../../../models/user';
import {Group} from './group';
import {Tag} from './tag';
import Status from './status';
import {Member} from './member';

@JsonObject()
export class FilterSprint {
    @JsonProperty(`archived`)
    @JsonType(Boolean)
    archived: boolean;

    @JsonProperty(`board_id`)
    @JsonType(Number)
    board_id: number;

    @JsonProperty(`color`)
    @JsonType(String)
    color: string;

    @JsonProperty(`description`)
    @JsonType(String)
    description: string;

    @JsonProperty(`id`)
    @JsonType(Number)
    id: number;

    @JsonProperty(`name`)
    @JsonType(String)
    name: string;

    @JsonProperty(`selected`)
    @JsonType(Boolean)
    selected: boolean;

    @JsonProperty(`tag`)
    @JsonType(String)
    tag: string;

    @JsonProperty(`task_id`)
    @JsonType(Number)
    task_id: number;

    constructor(id: number = null,
                color: string = '_grey',
                name: string = '',
    ) {
        this.id = id;
        this.name = name;
        this.color = color;
    }
}

@JsonObject()
export class FiltersAvailable {
    @JsonProperty(`authors`)
    @JsonElementType(Member)
    authors: Member[];

    @JsonProperty(`authors_archive`)
    @JsonElementType(Member)
    authorsArchive: Member[];

    @JsonProperty(`observers`)
    @JsonElementType(Member)
    observers: Member[];

    @JsonProperty(`observers_empty`)
    @JsonType(String)
    observersEmpty: string;

    @JsonProperty(`observers_archive`)
    @JsonElementType(Member)
    observersArchive: Member[];

    @JsonProperty(`performers`)
    @JsonElementType(Member)
    performers: Member[];

    @JsonProperty(`performers_empty`)
    @JsonType(String)
    performersEmpty: string;

    @JsonProperty(`performers_archive`)
    @JsonElementType(Member)
    performersArchive: Member[];

    @JsonProperty(`statuses`)
    @JsonElementType(Status)
    statuses: Status[];

    @JsonProperty(`groups`)
    @JsonElementType(Group)
    groups: Group[];

    @JsonProperty(`groups_empty`)
    @JsonType(String)
    groupsEmpty: string;

    @JsonProperty(`groups_archive`)
    @JsonElementType(Group)
    groupsArchive: Group[];

    @JsonProperty(`sprints`)
    @JsonElementType(FilterSprint)
    sprints: FilterSprint[];

    @JsonProperty(`sprints_empty`)
    @JsonType(String)
    sprintsEmpty: string;

    @JsonProperty(`sprints_archive`)
    @JsonElementType(FilterSprint)
    sprintsArchive: FilterSprint[];

    @JsonProperty(`tags`)
    @JsonElementType(Tag)
    tags: Tag[];

    @JsonProperty(`tags_empty`)
    @JsonType(String)
    tagsEmpty: string;

    @JsonProperty(`tags_archive`)
    @JsonElementType(Tag)
    tagsArchive: Tag[];
}
