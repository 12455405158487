<ng-container *ngIf="!folders">
    <mat-tree [dataSource]="dataSource"
              class="positions"
              *ngIf="!searchStr || !searchStr.length"
              [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node"
                       matTreeNodePadding
                       matTreeNodePaddingIndent="15">
            <div class="head">
                <button mat-icon-button disabled></button>
                <mat-icon svgIcon="radius_folder" class="icon _folder"></mat-icon>
                <span>{{node.name}}</span>
            </div>

        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                       matTreeNodePadding
                       matTreeNodePaddingIndent="15"
                       [ngClass]="{'_expanded': treeControl.isExpanded(node)}">
            <div class="folder">
                <div class="head" [ngClass]="{'_expanded': treeControl.isExpanded(node)}" *ngIf="node.id > 0" matTreeNodeToggle>
                    <button mat-icon-button
                            [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <mat-icon svgIcon="radius_folder" class="icon _folder"></mat-icon>
                    {{node.name}} ({{getCounterFolder(node)}})
                </div>

                <div class="body" *ngIf="(treeControl.isExpanded(node) || node.id < 0)">
                    <ng-container *ngFor="let item of node.positions, let index = index">
                        <app-position-list-item
                                class="position"
                                [rating]="false"
                                [selectable]="true"
                                (click)="onPositionClick(item, $event)"
                                [ngClass]="{'_root': node.id < 0, '_selected': isSelected(item)}"
                                [data]="item">
                        </app-position-list-item>
                    </ng-container>

                </div>
            </div>
        </mat-tree-node>
    </mat-tree>


    <ng-container *ngIf="searchStr && searchStr.length">
        <ng-container *ngFor="let item of sortedPositions, let index = index">
            <app-position-list-item
                    [searchStr]="searchStr"
                    class="position _root _search"
                    [rating]="false"
                    [selectable]="true"
                    (click)="onPositionClick(item, $event)"
                    [ngClass]="{'_selected': isSelected(item)}"
                    [data]="item">
            </app-position-list-item>
        </ng-container>

    </ng-container>

    <div class="position _root" *ngIf="canAdd">
        <a class="link" (click)="onCreateClick()"><mat-icon class="icon">add</mat-icon>Создать должность</a>
    </div>

</ng-container>

<ng-container *ngIf="folders">
    <mat-tree [dataSource]="dataSource"
              *ngIf="!searchStr || !searchStr.length"
              [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node"
                       matTreeNodePadding
                       matTreeNodePaddingIndent="15">
            <div class="head" [ngClass]="{'_selected': isSelected(node)}" (click)="onFolderClick(node, $event)">
                <button mat-icon-button disabled></button>
                <mat-icon svgIcon="radius_folder" class="icon _folder"></mat-icon>
                <span class="txt">{{node.name}}</span>
                <mat-icon *ngIf="isSelected(node)" class="icon _done" [svgIcon]="'radius_done'"></mat-icon>
            </div>

        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                       matTreeNodePadding
                       matTreeNodePaddingIndent="15">
            <div class="folder">
                <div class="head" [ngClass]="{'_selected': isSelected(node)}"
                     (click)="onFolderClick(node, $event)" *ngIf="node.id > 0">
                    <button mat-icon-button
                            matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <mat-icon svgIcon="radius_folder" class="icon _folder"></mat-icon>
                    <span class="txt">{{node.name}}</span>

                    <mat-icon *ngIf="isSelected(node)" class="icon _done" [svgIcon]="'radius_done'"></mat-icon>
                </div>
            </div>
        </mat-tree-node>
    </mat-tree>

    <ng-container *ngIf="searchStr && searchStr.length">
        <div class="mat-tree-node" *ngFor="let item of sortedFolders">
            <div class="head" [ngClass]="{'_selected': isSelected(item)}" (click)="onFolderClick(item, $event)">
                <mat-icon svgIcon="radius_folder" class="icon _folder"></mat-icon>
                <span class="txt" [innerHtml]="item.title | highlight : searchStr"></span>
                <mat-icon *ngIf="isSelected(item)" class="icon _done" [svgIcon]="'radius_done'"></mat-icon>
            </div>
        </div>
    </ng-container>

</ng-container>
