import {Calendar} from './models/calendar';

export interface CalendarDbItem {
    id: string,
    data: any,
}

export const calendarDb = {
    store: 'calendar',
    storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [
        { name: 'data', keypath: 'data', options: { unique: false } },
    ]
};
