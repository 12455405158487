import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Account} from '../../../../../../models/account';
import {Subject} from 'rxjs';
import {NavService} from 'src/app/modules/phonebook/services/nav.service';
import {sortEmails} from 'src/app/utils/commons';
import {EmailEditComponent} from 'src/app/modules/phonebook/components/users/email-edit/email-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {DialogInviteSuccessComponent} from '../../../../../phonebook/components/users/dialog-invite-success/dialog-invite-success.component';
import {AuthService} from '../../../../../../services/auth.service';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {

    public accounts: Account[] = [];
    public userId = null;
    private destroyed = new Subject<void>();
    timerStart = 30;
    timer = [];
    timerHandler = [];
    @Output() public readonly onChange: EventEmitter<any> = new EventEmitter();

    public gmailAccount = null;

    constructor(
        private dialog: MatDialog,
        private nav: NavService,
        private auth: AuthService,
    ) {
    }

    @Input() set data(params: Account[]) {
        this.accounts = [...params];
        sortEmails(this.accounts);
        this.accounts.forEach(el => {
            this.timer.push(0);
            this.timerHandler.push(null);
            if (el.isGmail) {
                this.gmailAccount = el.account;
            }
        });
    }

    @Input() set setUserId(params: number) {
        this.userId = params;
    }

    ngOnInit(): void {
    }


    get isNpm() {
        return this.auth.isNpm$.value;
    }

    get allGmails() {
        return this.accounts.filter(el => el.account.endsWith('gmail.com') && el.isVerified);
    }

    canDeleteFirst() {
        return this.accounts?.length > 1 && this.accounts.filter(el => el.isVerified).length > 1;
    }

    showModalEmail(flag, account = {account: ''}, index = null) {
        const dialogForm = this.dialog.open(EmailEditComponent, {
            width: '377px',
            data: {account: account,  flag:flag, userId: this.userId}
        });
        dialogForm.afterClosed().subscribe(result => {
            if (result === 'reset') {
                return;
            } else if (flag === 'delete' && result === 'delete')  {
                this.accounts.splice(index, 1);
            } else if (flag === 'edit' && !!result) {
                const index = this.accounts.findIndex(el => el === account);
                this.accounts[index].account = result;
            } else if (flag === 'create' && !!result) {
                const newAccount = {
                    type: '',
                    account: result,
                    isGmail :false,
                    isNotifiable: false,
                    isVerified:false
                };
                this.accounts.push(newAccount);
            }
            this.onChange.emit(this.accounts);
            this.nav.openUserById(this.userId);
        });
    }

    isGmailChange(account) {
        this.accounts.forEach(el => el.isGmail = false);
        if (account) {
            const value = account.value;
            const currentAccount = this.accounts.findIndex(el => el.account === value);
            this.accounts[currentAccount].isGmail = true;
        }
        this.onChange.emit(this.accounts);
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    startTimer(index) {
        this.timer[index] = this.timerStart;
        this.timerHandler[index] = setInterval(() => {
            this.timerTick(index);
        }, 1000);
    }

    timerTick(index) {
        if (this.timer[index] > 0) {
            this.timer[index] -= 1;
        } else {
            clearInterval(this.timerHandler[index]);
        }
    }

    sendInvite(email: String, index) {
        this.startTimer(index);
        const dialogForm = this.dialog.open(DialogInviteSuccessComponent, {
            height: '237px',
            width: '600px',
            panelClass: 'custom-dialog-container-invite',
            data: {account: email, userId: this.userId}
        });
    }
}
