<div class="sidebar-card kit">
    <div class="sidebar-card__header" *ngIf="question">
        <div class="sidebar-card__title" *ngIf="question.survey_type && question.survey_type !== 'delegate'">Оценка удовлетворенности</div>
        <div class="sidebar-card__title" *ngIf="question.survey_type && question.survey_type === 'delegate'">Оценка руководителя</div>
        <div class="sidebar-card__toolbar">
            <!--<button type="button" class="button _chat _icon" (click)="onOpenChatClick()">-->
                <!--<mat-icon [svgIcon]="'radius_chat'"></mat-icon>-->
            <!--</button>-->

            <span class="info-handler"
                  *ngIf="question && question.functionFull && question.survey_type && question.survey_type !== 'delegate'">
                <button type="button" class="button _icon">
                    <mat-icon svgIcon="radius_info" class="icon _info"></mat-icon>
                </button>

                    <span class="info-content">
                       <!--<span class="txt">-->
                           <!--Функция-->
                       <!--</span>-->

                        <span class="txt _main">
                            {{ question.functionFull.title }}
                        </span>

                        <div class="list"
                             *ngIf="question.functionFull.performersSubdivisions && question.functionFull.performersSubdivisions.length">
                            <div class="item" *ngFor="let sub of question.functionFull.performersSubdivisions">
                                <img class="logo" src="../../../../../../assets/icons/radius_eclipse.svg">
                                {{ sub.name }}
                            </div>
                        </div>

                    </span>
            </span>

            <button type="button" class="button _icon" (click)="onCloseClick()">
                <mat-icon aria-label="close">close</mat-icon>
            </button>
        </div>
    </div>

    <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="question && isLoaded && formGroup">
        <app-beta></app-beta>

        <h2 class="question">
            {{  question.question }}
        </h2>

        <!--    <div class="function" *ngIf="question.functionFull && question.survey_type && question.survey_type === 'delegate'">-->
        <div class="function" *ngIf="question.functionFull">
            <h3 class="function__title">{{ title }}</h3>
            <span class="function__name">{{ question.functionFull.title }}</span>

            <app-slots-list-item class="user" *ngIf="question && slot"
                [shortView]="false"
                [isPosition]="true"
                [shortPosition]="true"
                [positionTitle]="slot['positionTitle']"
                [slot]="slot" [disabled]="true"></app-slots-list-item>
        </div>

        <div class="response" *ngIf="question.answers">
            <app-question-answer *ngFor="let response of question.answers"
                          [data]="response"
                          class="answer"
                          (click)="onClickAnswer(response)"
                          [ngClass]="{'_selected': response.id === selectedId,
                          '_past': response.id === question.lastAnswer?.answer.id && !question.answerId,
                          '_anon': response.id === question.answer?.id && question.isAnonymous,
                          '_disabled' : disabled}"></app-question-answer>
        </div>

        <label class="label kit">
            <span class="label__txt">
                Комментарий
            </span>

            <textarea placeholder="Добавить комментарий..."
                      formControlName="comment"
                      class="textarea _grey"
                      autocomplete="off"
                      (keydown)="canSave = true"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="100"
            ></textarea>
        </label>

        <div class="last-answer" *ngIf="question.lastAnswer">
            <span class="last-answer__header">
                <span class="txt">Ваш прошлый ответ</span>
                <app-stars [data]="question.lastAnswer.answer.points"></app-stars>
            </span>
            <span class="txt">
                {{ question.lastAnswer.comment }}
            </span>
        </div>

        <div class="footer">
            <mat-checkbox class="checkbox"
                          *ngIf="question && question.survey_type !== 'delegate'"
                          title="Ответить анонимно"
                          formControlName="is_anonymous"
                          [checked]="!!question.isAnonymous"
                          (click)="$event.stopPropagation()">
                Ответить анонимно
            </mat-checkbox>

            <button type="submit" [disabled]="!canSave" mat-flat-button class="button _submit _blue">Ответить</button>
        </div>
    </form>

    <app-spinner [isSpinnerOn]="!isLoaded"></app-spinner>

</div>
