<mat-menu #menu="matMenu" class="header__settings-menu">
    <ng-template matMenuContent>
        <app-person-card [userId]="user.id">
        </app-person-card>
    </ng-template>

</mat-menu>

<div *ngIf="user && !nameMembers"
     [ngClass]="{'_active': action || isCanEdit, '_filters': filtersMode, '_hover-name': hoverName}"
     class="member">

    <ng-container *ngIf="!filtersMode && !total">

        <div *ngIf="responsible && !user.unread" class="icon-container _responsive">
            <mat-icon class="responsive" matTooltip="Ответственный" svgIcon="radius_star-border"></mat-icon>
        </div>

        <div *ngIf="user.unread && responsible" class="icon-container">
            <mat-icon class="unread" matTooltip="Задача не просмотрена">visibility_off</mat-icon>
        </div>

        <div *ngIf="user.unread && performer" class="icon-container">
            <mat-icon class="unread" matTooltip="Задача не просмотрена">visibility_off</mat-icon>
        </div>

        <app-user-avatar *ngIf="user.id > 0" [matMenuTriggerFor]="menu" [matTooltip]="user.shortName"
                         [user]="user"
                         class="member__img"></app-user-avatar>
        <span (click)="onEditClick($event)" *ngIf="!shortView"
              [ngClass]="{'_role' : responsible || performer, '_responsible': responsible, '_edit':  isCanEdit}"
              class="member__title txt">
          <span class="name" [ngClass]="{'_stat': isStatsVisible}">
            {{ getUserName() }}
          </span>

           <span *ngIf="date" class="member__subtitle">
              {{ date }}
          </span>
          <span *ngIf="responsible" class="member__subtitle">
              <span>Ответственный</span>
          </span>
          <span *ngIf="performer" class="member__subtitle">
              <span>Исполнитель</span>
          </span>
          <mat-icon *ngIf="isCanEdit" class="icon _edit">edit</mat-icon>
        </span>

        <ng-container *ngIf="action && action === 'remove' && canBeDeleted() && isCanDelete">
            <mat-icon (click)="onClick()" class="icon remove-icon">close</mat-icon>
        </ng-container>

        <ng-container *ngIf="(performer || responsible) && taskId && !isSmartTask && showAddToMatrixBtn">
            <a class="matrix-link _add-matrix-btn"
               [ngClass]="{'_can-add': canAddToMatrix}"
               (click)="onAddToMatrix()"
            >
                <mat-icon svgIcon="radius_matrix" class="icon _matrix-icon _margin _add_matrix_icon"
                          [matTooltip]="tooltip"
                          [matTooltipClass]="'_white-tooltip'"
                ></mat-icon>
            </a>
        </ng-container>

        <ng-container *ngIf="isSmartTask">
            <a [href]="getMatrixLink()" *ngIf="isSmartTaskCanEdit" class="matrix-link">
                <mat-icon #trigger="cdkOverlayOrigin" (mouseleave)="hidePopup()"
                          (mouseover)="showPopup(trigger)"
                          cdkOverlayOrigin
                          class="icon _matrix-icon _margin"
                          svgIcon="radius_matrix"
                ></mat-icon>
            </a>

            <a class="matrix-link _is-smart-task" *ngIf="!isSmartTaskCanEdit"
               [ngClass]="{'_can-add': canAddToMatrix}"
            >
                <mat-icon svgIcon="radius_matrix" class="icon _matrix-icon _margin _add_matrix_icon"
                          [matTooltip]="this.isDetailsVisible() ? undefined : tooltip"
                          #trigger="cdkOverlayOrigin"
                          [matTooltipClass]="'_white-tooltip'"
                          (mouseleave)="hidePopup()"
                          (mouseover)="showPopup(trigger)"
                          cdkOverlayOrigin
                ></mat-icon>
            </a>
        </ng-container>

        <!-- This template displays the overlay content -->
        <ng-template
                [cdkConnectedOverlayOpen]="isOpen"
                [cdkConnectedOverlayOrigin]="overlayOrigin"
                cdkConnectedOverlay
        >
            <div class="panel"  *ngIf="this.isDetailsVisible()">
                <h3 class="panel__heading">Задача добавлена в матрицу</h3>

                <div>
                    <div class="panel__row" *ngIf="availableMatrices?.matrix?.approvePlan">
                        <div>Начальная стоимость</div>
                        <div class="panel__row-val">
                            {{ formatCurrency(smartTaskInfo.getBasicSum()) }}
                        </div>
                    </div>
                    <div class="panel__row" *ngIf="availableMatrices?.matrix?.approveFact">
                        <div>Фактическая стоимость</div>
                        <div class="panel__row-val">
                            {{ formatCurrency(smartTaskInfo.getSumAfterQualityPenalty(ratio)) }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-container *ngIf="isStatsVisible">
            <mat-icon (mouseover)="onHover($event)" [ngClass]="getColor()"
                      class="icon _stat info" svgIcon="radius_statistics"></mat-icon>
            <div [ngStyle]="{'top.px': top + 20, 'left.px': left - 150 }" class="info__content">
                <span class="txt _title">Загрузка на спринт</span>
                <div class="info__row">
                    <span class="txt">  Плановая нагрузка</span><span class="txt _num">{{ getUserPlaningPoints(user?.stats?.plan) }}</span>
                </div>
                <div class="info__row">
                    <span class="txt">Плановая оценка задач</span><span [ngClass]="getColor()"
                                                                        class="txt _num">{{ getUserPlaningPoints(user?.stats?.totalNew.coef) }}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="action && action === 'add'">
            <mat-icon class="icon add-icon">add</mat-icon>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="filtersMode">
        <app-user-avatar *ngIf="user.id > 0"
                         [matTooltip]="user.shortName" [user]="user" class="member__img"></app-user-avatar>
        <span *ngIf="!user || !user.id" class="member__img _empty">
      <mat-icon class="icon">person_off</mat-icon>
    </span>
        <span *ngIf="!shortView" class="member__title txt">
      <ng-container>
        {{ userId === user.id ? 'Я' : user.firstName }}
      </ng-container>
    </span>
        <ng-container *ngIf="action && action === 'remove' && canBeDeleted()">
            <mat-icon (click)="onClick()" class="icon remove-icon">close</mat-icon>
        </ng-container>
        <ng-container *ngIf="action && action === 'add'">
            <mat-icon class="icon add-icon">add</mat-icon>
        </ng-container>
    </ng-container>

    <div [matTooltip]="user.shortName" class="close">
        <mat-icon class="icon">close</mat-icon>
    </div>

    <div *ngIf="total" class="total-points">
        {{ total }} ч

        <ng-container *ngIf="action && action === 'remove' && canBeDeleted() && isCanDelete">
            <mat-icon (click)="onClick()" class="icon remove-icon" svgIcon="radius_delete-outline"></mat-icon>
        </ng-container>
    </div>
</div>

<div (click)="onClick()" *ngIf="user && nameMembers" [ngClass]="{'_active': action, '_filters': filtersMode}"
     class="item__shorting">
    <div class="wrapper__img">
        <app-user-avatar *ngIf="user.id > 0"
                         [matMenuTriggerFor]="menu"
                         [matTooltip]="user.shortName" [user]="user" class="member__img"></app-user-avatar>
        <span *ngIf="!user || !user.id" class="member__img _empty">
      <mat-icon class="icon">person_off</mat-icon>
    </span>
        <div class="hover__img">
            <mat-icon class="icon__img">close</mat-icon>
        </div>
    </div>

    <div [matTooltip]="user.shortName" class="close">
        <mat-icon class="icon">close</mat-icon>
    </div>
</div>
