<span class="wrappper" [ngClass]="{'_focus': focused, '_large': large}" (click)="onFieldClick()">
    <label class="label">
        <input class="input _hours" type="text"
               [placeholder]="large ? '00' : '0'"
               [mask]="large ? '000' : '00'"
               [disabled]="disabled"
               [(ngModel)]="h"
               #hourEl
               (focusin)="hFocused = true"
               (focusout)="hFocused = false; save()"
               (keyup)="onChangeHours($event)"
               (keydown.Tab)="onTab($event)">
        <span class="txt _h">ч</span>
    </label>

    <label class="label" max="59">
        <input class="input _minutes" type="text" placeholder="00"
               [mask]="'00'"
               [(ngModel)]="m"
               [disabled]="disabled"
               #minEl
               (focusin)="mFocused = true"
               (focusout)="mFocused = false; save()"
               (keyup)="onChangeMinutes($event)">
         <span class="txt _h">м</span>
    </label>

</span>