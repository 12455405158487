import {JsonElementType, JsonProperty, JsonType} from 'ta-json';

export class WorkdaySnapshotDate {
    @JsonProperty('date')
    @JsonType(Date)
    date: Date = null;

    @JsonProperty('value')
    @JsonType(Number)
    value: number = null;

    @JsonProperty('updated_at')
    @JsonType(Date)
    updatedAt: Date = null;
}

export class WorkdaySnapshotData {
    @JsonProperty('total')
    @JsonType(Number)
    total: number = null;

    @JsonProperty('dates')
    @JsonElementType(WorkdaySnapshotDate)
    dates: WorkdaySnapshotDate[] = [];

    @JsonProperty('updated_at')
    @JsonType(Date)
    updatedAt: Date = null;
}

export class PositionsFunctionFrdStat {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    // Уровень группировки (надо прибавлять 1)
    @JsonProperty('clustering_level')
    @JsonType(Number)
    clusteringLevel: number = null;

    // Функция или папка родительская
    @JsonProperty('is_cluster_folder')
    @JsonType(Boolean)
    isClusterFolder: boolean = false;

    @JsonProperty('is_folder')
    @JsonType(Boolean)
    isFolder: boolean = false;

    @JsonProperty('path')
    @JsonType(Array)
    path = [];

    @JsonProperty('title')
    @JsonType(String)
    title: string = '';

    // Регулярная или Проектная
    @JsonProperty('type')
    @JsonType(String)
    type: string = '';

    @JsonProperty('periodicity')
    @JsonType(String)
    periodicity = '';

    @JsonProperty('workday_snapshot_data')
    @JsonElementType(WorkdaySnapshotData)
    workdaySnapshotData: WorkdaySnapshotData = null;

    // Расчетная загрузка (автоматический расчет итого вой суммы)
    @JsonProperty('total')
    @JsonType(Number)
    total: number = null;

    //Корректируемое поле итого
    @JsonProperty('total_adjusted')
    @JsonType(Number)
    totalAdjusted: number = null;

    // Итого за месяц (финальное  значение)
    @JsonProperty('workday_snapshot_duration')
    @JsonType(Number)
    workdaySnapshotDuration: number = null;

    // Редактируемое поле для загрузки
    workdaySnapshotDurationEditable = 0;

    // Сворачивание папок
    collapsed = false;

    // Выделение чекбоксами
    selected = false;
}


