<div class="dialog">
    <div class="header">
        <mat-icon
            class="close"
            (click)="close()"
        >
            close
        </mat-icon>
    </div>
    <div class="content">
        <h3
            [innerHtml]="title"
            class="title"
        ></h3>
        <h3
            [innerHtml]="description"
            class="description"
            *ngIf="!!description"
        ></h3>

        <mat-checkbox
            *ngIf="checkbox"
            [(ngModel)]="checked" class="checkbox"
        >
            {{ checkbox.title }}
        </mat-checkbox>

        <section
            *ngIf="link && link.length"
            class="link-box"
        >
            <a
                class="link"
                (click)="onLinkClick($event)"
            >
                {{ link }}
            </a>
        </section>
    </div>

    <div
        class="footer"
        *ngIf="buttons && buttons.length === 2"
    >
        <app-modal-button
            *ngFor="let button of buttons; let index = index"
            class="button"
            tabindex="2"
            [buttonType]="index === 0 ? 'outlined' : 'field'"
            autoFocus="false"
            [title]="button.name"
            (click)="action(button.action)"
        ></app-modal-button>
    </div>

    <div
        class="footer"
        *ngIf="buttons && buttons.length !== 2"
    >
        <button
            *ngFor="let button of buttons"
            tabindex="2"
            mat-flat-button
            [autoFocus]="button.autofocus"
            class="button"
            [ngClass]="button.color"
            (click)="action(button.action)"
        >
            {{ button.name }}
        </button>
    </div>
</div>
