import {FunctionPosition, FunctionShort, FunctionSubdivision} from './function-short';
import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {FunctionQuestion} from './function-question';
import {UserFunction} from '../user-function';

export class FunctionFull extends FunctionShort {
    @JsonProperty('type')
    @JsonType(String)
    type = '';

    @JsonProperty('periodicity')
    @JsonType(String)
    periodicity = '';

    @JsonProperty('description')
    @JsonType(String)
    description = '';

    @JsonProperty('dod')
    @JsonType(String)
    dod = '';

    @JsonProperty('final_product')
    @JsonType(String)
    finalProduct = '';

    @JsonProperty('performers_control_frequency')
    @JsonType(Number)
    performers_control_frequency = 7;

    @JsonProperty('delegates_control_frequency')
    @JsonType(Number)
    delegates_control_frequency = 7;

    @JsonProperty('customers_control_frequency')
    @JsonType(Number)
    customers_control_frequency = 7;

    @JsonProperty('control_frequency')
    @JsonType(Number)
    controlFrequency = 7;

    @JsonProperty('customers')
    @JsonElementType(UserFunction)
    customers: UserFunction[];

    @JsonProperty('customers_positions')
    @JsonElementType(FunctionPosition)
    customersPositions: FunctionPosition[] = [];

    @JsonProperty('delegates_subdivisions')
    @JsonElementType(FunctionSubdivision)
    delegatesSubdivisions: FunctionSubdivision[] = [];

    @JsonProperty('delegates_positions')
    @JsonElementType(FunctionPosition)
    delegatesPositions: FunctionPosition[] = [];

    @JsonProperty('customers_subdivisions')
    @JsonElementType(FunctionSubdivision)
    customersSubdivisions: FunctionSubdivision[] = [];

    @JsonProperty('performers_positions')
    @JsonElementType(FunctionPosition)
    performersPositions: FunctionPosition[] = [];

    @JsonProperty('responsibles_positions')
    @JsonElementType(FunctionPosition)
    responsiblesPositions: FunctionPosition[] = [];

    @JsonProperty('performers_subdivisions')
    @JsonElementType(FunctionSubdivision)
    performersSubdivisions: FunctionSubdivision[] = [];

    @JsonProperty('unit')
    @JsonType(String)
    unit: string;

    @JsonProperty('parent')
    @JsonType(FunctionShort)
    parent: FunctionShort;

    @JsonProperty('performers_questions')
    @JsonElementType(FunctionQuestion)
    performers_questions: FunctionQuestion[] = [];

    @JsonProperty('delegates_questions')
    @JsonElementType(FunctionQuestion)
    delegates_questions: FunctionQuestion[] = [];

    @JsonProperty('customers_questions')
    @JsonElementType(FunctionQuestion)
    customers_questions: FunctionQuestion[] = [];

    @JsonProperty('questions')
    @JsonElementType(FunctionQuestion)
    questions: FunctionQuestion[] = [];

    @JsonProperty('unread_messages')
    @JsonType(Number)
    messages: number;

    @JsonProperty('chart_data')
    @JsonType(Array)
    chart_data = [];

    @JsonProperty('is_nested_calculation')
    @JsonType(Boolean)
    is_nested_calculation: boolean;

    @JsonProperty('image')
    @JsonType(String)
    image: string = null;
}

export const CLUSTERING_LEVELS = [
    {
        type: 0,
        short: 'I',
        name: 'I уровень'
    },
    {
        type: 1,
        short: 'II',
        name: 'II уровень'
    },
    {
        type: 2,
        short: 'III',
        name: 'III уровень'
    },
    {
        type: 3,
        short: 'IV',
        name: 'IV уровень'
    },
    {
        type: 4,
        short: 'V',
        name: 'V уровень'
    }
];

export const TYPES = [
    {
        type: 'regular',
        name: 'Регулярная',
        short: 'Р',
        description: 'Регулярная функция - это функция,\n' +
            '                               которая выполняется с определенной периодичностью и содержащая одинаковые,\n' +
            '                                не отличающиеся друг от друга операции'
    },
    {
        type: 'project',
        name: 'Проектная',
        short: 'П',
        description: 'Проектная функция - это функция,\n' +
            '                              для выполнения которой сотруднику регулярно ставятся конкретные задачи,\n' +
            '                              имеющие конечный период выполнения'
    }
];

export const PERIODICITY_TYPES = [
    {
        type: 'day',
        name: 'Ежедневная'
    },
    {
        type: 'week',
        name: 'Еженедельная'
    },
    {
        type: 'month',
        name: 'Раз в месяц'
    },
    {
        type: 'quarter',
        name: 'Раз в квартал'
    },
    {
        type: 'year',
        name: 'Раз в год'
    }
];