import {Component, HostListener, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OnboardingService} from '../../../../../services/onboarding.service';
import {AuthService} from '../../../../../services/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SkipConfirmDialogComponent} from '../skip-confirm-dialog/skip-confirm-dialog.component';
import IntroJs from 'intro.js/intro.js';
import {ShortFormAddBoardComponent} from '../../../../task-manager/components/boards-menu/short-form-add-board/short-form-add-board.component';
import {BoardsService} from '../../../../task-manager/services/boards.service';
import {SharedService} from '../../../services/shared.service';
import {IStepOption, TourService} from 'ngx-ui-tour-md-menu';
import {Router} from '@angular/router';
import {NavService} from '../../../../task-manager/services/nav.service';
import {TasksService} from '../../../../task-manager/services/tasks.service';
import {ApiBoardsService} from '../../../../task-manager/services/api-boards.service';
import {GlobalNotificationCenterService} from '../../../../../services/global-notification-center.service';
import {ConfigBoardsService} from '../../../../task-manager/services/config-boards.service';

@Component({
    selector: 'app-learning-tasks',
    templateUrl: './learning-tasks.component.html',
    styleUrls: ['./learning-tasks.component.scss']
})
export class LearningTasksComponent implements OnInit, OnDestroy {
    private destroyed = new Subject<void>();
    isVisible = false;
    compliteIntro = false;
    ONBOARDING_KEY = 'tasks';
    boardId = null;
    fromBack = false;

    isPause = false;
    slides = [];
    isAdmin = false;
    inited = false;
    lastStepShown = null;
    // stepDone = 0;
    public readonly tourService = inject(TourService);

    readonly tourSteps: IStepOption[] = [{
        anchorId: 'learningStartStep',
        content: 'Давайте начнем с раздела “Доски”',
        enableBackdrop: true,
        title: 'Доски',
    }, {
        anchorId: 'tasksLearningBoardMenu',
        content: 'В разделе “Доски” вы можете смотреть и редактировать ранее созданные доски или создать свою. Также здесь находится ваша “Личная доска”, на которой дублируются ваши задачи, где вы исполнитель и наблюдатель.',
        isAsync: true,
        title: 'Доски',
        // placement: {xPosition: 'after'},
    }, {
        anchorId: 'LearningAddBoard',
        stepId: '2',
        content: 'Давайте создадим новую доску',
        placement: {yPosition: 'above'},
        enableBackdrop: true,
    }, {
        anchorId: 'LearningAddBoardForm',
        stepId: '3',
        content: 'Вводим название. Например, “Новая доска”',
        placement: {yPosition: 'above'},
    }, {
        anchorId: 'LearningAddBoardForm',
        stepId: '4',
        delayBeforeStepShow: 100,
        content: 'И нажимаем на галочку или клавишу Enter для создания',
        placement: {yPosition: 'above'},
        allowUserInitiatedNavigation: true

    }, {
        anchorId: 'learningBoard',
        title: 'Отлично! Мы создали первую доску',
        stepId: '5',
        isAsync: true,
        delayBeforeStepShow: 100,
        allowUserInitiatedNavigation: true,
        nextStep: '6',
        content: 'В редактировании доски вы можете изменить такие параметры, как описание, участники, настройка прав, отображение статусов и другие'
    }, {
        anchorId: 'learningBoardClose',
        stepId: '6',
        prevStep: '5',
        isAsync: true,
        allowUserInitiatedNavigation: true,
        content: 'Теперь мы можем закрыть доску и перейти к следующему шагу'
    }, {
        title: 'Создание задачи',
        anchorId: 'learningTaskCreate',
        stepId: '7',
        enableBackdrop: true,
        content: 'А теперь давайте создадим первую задачу'
    }, {
        title: 'Первая задача',
        isAsync: true,
        stepId: '8',
        route: 'apps/boards/task/new',
        anchorId: 'learningNameTask',
        content: 'Сначала введем название задачи. Например, “Моя первая задача”'
    }, {
        title: 'Описание задачи',
        route: 'apps/boards/task/new',
        anchorId: 'learningDescriptionTask',
        content: 'Теперь добавим описание задачи'
    }, {
        title: 'Планируемый результат',
        route: 'apps/boards/task/new',
        anchorId: 'learningDodTask',
        content: 'Далее добавляем планируемый результат'
    }, {
        title: 'Чек-лист задачи',
        anchorId: 'learningChecklistTask',
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        stepId: '11',
        content: 'Теперь создадим чек-лист новой задачи'
    }, {
        title: 'Чек-лист задачи',
        anchorId: 'learningChecklistTask',
        route: 'apps/boards/task/new',
        delayBeforeStepShow: 100,
        // centerAnchorOnScroll: true,
        stepId: '12',
        content: 'Добавим еще один пункт'
    }, {
        title: 'Чек-лист задачи',
        anchorId: 'learningChecklistTask',
        delayBeforeStepShow: 100,
        route: 'apps/boards/task/new',
        // centerAnchorOnScroll: true,
        stepId: '13',
        content: 'И еще один!'
    }, {
        title: 'Чек-лист задачи',
        anchorId: 'learningChecklistTask',
        route: 'apps/boards/task/new',
        stepId: '14',
        //   centerAnchorOnScroll: true,
        delayBeforeStepShow: 100,
        content: 'Отметим первые два как выполненные (нажатием на чекбокс).'
    }, {
        title: 'Преобразование чек-листа',
        route: 'apps/boards/task/new',
        // centerAnchorOnScroll: true,
        anchorId: 'learningChecklistTask',
        delayBeforeStepShow: 100,
        placement: {yPosition: 'below'},
        stepId: '15',
        content: 'Отлично!' + '<br>'
        + 'Теперь, нажав три точки вверху блока чек-листов вы можете добавить существующую подзадачу или же преобразовать чек-лист в подзадачи. Но на этом мы останавливаться не будем'
    }, {
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        enableBackdrop: false,
        delayBeforeStepShow: 100,
        anchorId: 'learningDateTask',
        content: 'Далее установим задаче дату начала и дату выполнения'
    }, {
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        enableBackdrop: false,
        delayBeforeStepShow: 100,
        isOptional: true,
        anchorId: 'learningMembersTask',
        content: 'После того, как мы установили задаче даты начала и выполнения, можно выбрать участников задачи'
    }, {
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        enableBackdrop: false,
        delayBeforeStepShow: 100,
        isOptional: true,
        anchorId: 'learningMembersWideTask',
        content: 'После того, как мы установили задаче даты начала и выполнения, можно выбрать участников задачи'
    }, {
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        enableBackdrop: false,
        delayBeforeStepShow: 100,
        anchorId: 'learningMembersTask',
        isOptional: true,
        stepId: '18',
        content: 'Можно назначить себя исполнителем, наблюдателем или ответственным напрямую или добавить другого сотрудника на выбранную роль.'
    },{
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        enableBackdrop: false,
        delayBeforeStepShow: 100,
        anchorId: 'learningMembersWideTask',
        isOptional: true,
        stepId: '18',
        content: 'Можно назначить себя исполнителем, наблюдателем или ответственным напрямую или добавить другого сотрудника на выбранную роль.'
    }, {
        title: 'Выбор участников задачи',
        route: 'apps/boards/task/new',
        delayBeforeStepShow: 100,
        anchorId: 'learningMembersDialog',
        isAsync: true,
        enableBackdrop: false,
        content: 'В этом окне доступно добавление сотрудников в необходимую роль в задаче. Один сотрудник может быть только в одной из ролей.'
    }, {
        title: 'Вложения задачи',
        centerAnchorOnScroll: true,
        route: 'apps/boards/task/new',
        anchorId: 'learningFileuploadTask',
        delayBeforeStepShow: 100,
        stepId: '21',
        content: 'В задачу можно добавлять различные вложения - документы, изображения, аудио и видео файлы'
    }, {
        title: 'Вложения задачи',
        route: 'apps/boards/task/new',
        isAsync: true,
        delayBeforeStepShow: 100,
        anchorId: 'learningFileuploadDialog',
        stepId: '22',
        content: 'Вложение можно загрузить с компьютера, добавить ссылкой или перетащить'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        anchorId: 'learningFileuploadLink',
        stepId: '23',
        delayBeforeStepShow: 100,
        content: 'Прикрепляем ссылку для примера'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        placement: {yPosition: 'above'},
        anchorId: 'learningFileuploadSave',
        stepId: '24',
        content: 'После нажатия прикрепить остается лишь сохранить и выйти из текущего окна'
    }, {
        title: 'Группа задачи',
        route: 'apps/boards/task/new',
        centerAnchorOnScroll: true,
        anchorId: 'learningGroupTask',
        stepId: '25',
        delayBeforeStepShow: 100,
        content: 'Теперь выберем группу задач. Для этого необходимо выбрать "Добавить группу"'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        anchorId: 'learningGroupAddDialog',
        stepId: '27',
        placement: {xPosition: 'after'},
        content: 'Вводим название группы и выбираем цвет, которым будет выделяться данная группа. После ввода нажимаем “Готово”'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        title: 'Метки задачи',
        anchorId: 'learningTagTask',
        stepId: '28',
        prevStep: '25',
        content: 'Для каждой задачи можно добавить метку. Для этого нажимаем “+ Добавить метку”'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        anchorId: 'learningTagDialog',
        stepId: '29',
        placement: {xPosition: 'after'},
        content: 'При наличии меток с помощью поиска вы можете найти существующую метку и добавить ее в задачу. Также вы можете создать новую метку, нажав “+ Добавить метку”'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        anchorId: 'learningTagAddDialog',
        stepId: '30',
        content: 'Вводим название метки и выбираем цвет, которым будет выделяться данная метка. После ввода нажимаем “Готово”'
    }, {
        route: 'apps/boards/task/new',
        isAsync: true,
        anchorId: 'learningSaveTask',
        stepId: '31',
        prevStep: '28',
        content: 'И последний шаг - сохраняем задачу'
    },
    ];

    @ViewChild(ShortFormAddBoardComponent) boardName: ShortFormAddBoardComponent;


    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (this.sharedService.openTaskLearning$.value) {
            if (event.code !== 'ArrowRight' && event.code !== 'ArrowLeft' && event.code !== 'Escape') {
                event.preventDefault();
            }
            if (event.code === 'Escape') {
                event.preventDefault();
                this.tourService.pause();
            }

        }
    }

    constructor(
        private onboarding: OnboardingService,
        private auth: AuthService,
        public dialog: MatDialog,
        private router: Router,
        private tasksService: TasksService,
        private boardsService: BoardsService,
        private nav: NavService,
        private sharedService: SharedService,
        private apiBoards: ApiBoardsService,
        private config: ConfigBoardsService,
        private notiService: GlobalNotificationCenterService,
    ) {
    }

    get isBefireBoardCreate() {
        return this.tourService.currentStep.anchorId === 'learningStartStep'
            || this.tourService.currentStep.anchorId === 'tasksLearningBoardMenu'
            || this.tourService.currentStep.anchorId === 'LearningAddBoard'
            || this.tourService.currentStep.stepId === '3';
    }

    ngOnInit(): void {

        this.tourService.initialize(this.tourSteps, {
            // route: 'apps/boards',
            closeOnOutsideClick: false,
            showProgress: true,
            enableBackdrop: false,
            allowUserInitiatedNavigation: true,
            backdropConfig: {backgroundColor: 'rgba(23, 31, 70, 0.5)'},
            showArrow: false,
            stepDimensions: {maxWidth: '285px', minWidth: '280px', width: 'auto'}
        });

        this.tourService.end$
            .pipe(takeUntil(this.destroyed))
            .subscribe((el) => {
                if (this.lastStepShown && this.lastStepShown.anchorId === 'learningSaveTask') {
                    this.compliteIntro = true;
                    this.sharedService.openTaskLearning$.next(false);
                    this.sharedService.learningBoardName$.next('done');
                }
                this.isVisible = false;
                this.sharedService.openTaskLearning$.next(false);
            }, error => {
                console.log(error);
                this.sharedService.openTaskLearning$.next(false);
            });

        this.tourService.pause$
            .pipe(takeUntil(this.destroyed))
            .subscribe((el) => {
                this.isPause = true;
                const dialogRef = this.dialog.open(SkipConfirmDialogComponent, {data: {title: 'обучение'}});

                dialogRef.afterClosed()
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(result => {
                        if (!this.isBefireBoardCreate && !this.fromBack && this.boardsService.boardSelected$.value) {
                            this.boardId = this.boardsService.boardSelected$.value.id;
                        }

                        if (result) {
                            this.openAnotherIntro();
                            this.closeFullScreen();
                        } else {
                            let event = new Event('click');
                            /** Если попробовать выйти из обучения на шагах с модалками */
                            if (this.tourService.currentStep.stepId === '4') {
                                document.querySelector('.learningBoardClose').dispatchEvent(event);
                                this.deleteBoard();
                                document.querySelector('.learningOpenCreateBoard').dispatchEvent(event);
                                this.sharedService.learningBoardName$.next('Новая доска');
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.stepId === '6') {
                                this.tourService.startAt('7');
                            }

                            if (this.tourService.currentStep.anchorId === 'learningTaskCreate') {
                                this.tourService.startAt('8');
                            }

                            if (this.tourService.currentStep.anchorId === 'learningMembersDialog') {
                                document.querySelector('.learningMembersAdd').dispatchEvent(event);
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.anchorId === 'learningFileuploadTask') {
                                document.querySelector('.learningCancelAttachment').dispatchEvent(event);
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.stepId === '22') {
                                this.sharedService.learningBoardName$.next('');
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.stepId === '23') {
                                this.sharedService.learningBoardName$.next('Вложение');
                                document.querySelector('.learningDeleteLink').dispatchEvent(event);
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.stepId === '24') {
                                document.querySelector('.learningRepeatFileuploadTask').dispatchEvent(event);
                                this.sharedService.learningBoardName$.next('Вложение повтор');
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.anchorId === 'learningGroupDialog') {
                                document.querySelector('.learningGroupAddClose').dispatchEvent(event);
                                this.tourService.resume();
                            }

                            if (this.tourService.currentStep.anchorId === 'learningGroupAddDialog') {
                                this.tourService.startAt('28');
                            }

                            if (this.tourService.currentStep.anchorId === 'learningTagTask') {
                                document.querySelector('.learningTagClose').dispatchEvent(event);
                            }

                            if (this.tourService.currentStep.anchorId === 'learningTagDialog') {
                                document.querySelector('.learningTagAddClose').dispatchEvent(event);
                                this.tourService.startAt('28');
                            }

                            if (this.tourService.currentStep.anchorId === 'learningTagAddDialog') {
                                this.tourService.startAt('31');
                            } else {
                                this.tourService.resume();
                            }

                        }
                        this.isPause = false;
                    });

            }, error => {
                console.log(error);
                this.sharedService.openTaskLearning$.next(false);
            });
        /*this.tourService.events$
            .pipe(takeUntil(this.destroyed))
            .subscribe(el => {
                if (!document.querySelector('.learning-card') && this.sharedService.openTaskLearning$.value) {
                    this.sharedService.openTaskLearning$.value
                }
            });*/

        this.tourService
            .stepShow$
            .pipe(takeUntil(this.destroyed))
            .subscribe((el) => {
                if (el.step.stepId === '5') {
                    this.sharedService.learningBoardName$.next('');

                }

                if (el.step.stepId === '6') {
                    document.querySelector('.learningBoardClose').classList.add('my-anchor-el');
                }
                if (el.step.anchorId === 'learningNameTask') {
                    document.querySelector('.learning-name').classList.add('my-anchor-el');
                    document.getElementById('titleElem').blur();
                }

                if (el.step.anchorId === 'learningDescriptionTask') {
                    document.querySelector('.learningDescription').classList.add('my-anchor-el');
                }

                if (el.step.anchorId === 'learningDodTask') {
                    document.querySelector('.learningDod').classList.add('my-anchor-el');
                }

                if (el.step.stepId === '11') {
                    document.querySelector('.learningChecklist').classList.add('my-anchor-el');
                }

                if (el.step.stepId === '15') {
                    document.querySelector('.learningChecklist').classList.remove('my-anchor-el');
                    document.querySelector('.learningChecklistMenu').classList.add('my-anchor-el');
                }

                if (el.step.anchorId === 'learningDateTask') {
                    document.querySelector('.learningDate').classList.add('my-anchor-el');
                }

                if (el.step.anchorId === 'learningMembersTask' || el.step.anchorId === 'learningMembersWideTask') {
                    document.querySelector('.learningMembers').classList.add('my-anchor-el');
                }

                if (el.step.anchorId === 'learningFileuploadTask') {
                    document.querySelector('.learningFileupload').classList.add('my-anchor-el');
                }
                if (el.step.anchorId === 'learningGroupTask') {
                    this.sharedService.learningBoardName$.next('Группа');
                    document.querySelector('.learningGroup').classList.add('my-anchor-el');
                }

                if (el.step.anchorId === 'learningTagTask') {
                    document.querySelector('.learningTag').classList.add('my-anchor-el');
                }
            }, error => {
                this.sharedService.openTaskLearning$.next(false);
            });

        this.tourService
            .stepHide$
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (el) => {
                    let event = new Event('click');
                    this.lastStepShown = el.step;
                    if (el.direction === 0) {

                        if (el.step.anchorId === 'learningStartStep') {
                            this.sharedService.learningBoardName$.next('');
                            document.querySelector('.tasksSecondStep').dispatchEvent(event);
                        }

                        if (el.step.stepId === '2') {
                            let event = new Event('click');
                            document.querySelector('.learningOpenCreateBoard').dispatchEvent(event);
                        }
                        if (el.step.stepId === '3') {
                            this.sharedService.learningBoardName$.next('Новая доска');
                        }
                        if (el.step.stepId === '4' && this.sharedService.learningBoardName$.value !== 'done') {
                            this.fromBack = false;
                            this.sharedService.learningBoardName$.next('Save board');
                        }

                        if (el.step.stepId === '6') {
                            this.sharedService.learningBoardName$.next('');
                            document.querySelector('.learningBoardClose').dispatchEvent(event);
                            document.querySelector('.learningBoardsClose').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningTaskCreate') {
                            document.querySelector('.learningTaskCreate').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('Моя первая задача');
                        }
                        if (el.step.anchorId === 'learningNameTask') {
                            document.querySelector('.learning-name').classList.remove('my-anchor-el');
                            this.sharedService.learningBoardName$.next('Здесь вводится описание задачи');
                        }
                        if (el.step.anchorId === 'learningDescriptionTask') {
                            document.querySelector('.learningDescription').classList.remove('my-anchor-el');
                            this.sharedService.learningBoardName$.next('Сотрудник научился работать с досками и задачами');
                        }
                        if (el.step.anchorId === 'learningDodTask') {
                            document.querySelector('.learningDod').classList.remove('my-anchor-el');
                            this.sharedService.learningBoardName$.next('Пройти введение в раздел задач');
                        }

                        /** Идентифицируем шаги по id шага, тк выделяем один элемент на несколько шагов */
                        if (el.step.stepId === '11') {
                            this.sharedService.learningBoardName$.next('Научиться создавать доски');
                        }
                        if (el.step.stepId === '12') {
                            this.sharedService.learningBoardName$.next('Научиться создавать задачи');
                        }
                        if (el.step.stepId === '13') {
                            this.sharedService.learningBoardName$.next('');
                            this.sharedService.learningSetSelected$.next(true);
                        }
                        if (el.step.stepId === '15') {
                            document.querySelector('.learningChecklist').classList.remove('my-anchor-el');
                            this.sharedService.learningBoardName$.next('Дата');
                        }
                        if (el.step.anchorId === 'learningDateTask') {
                            document.querySelector('.learningDate').classList.remove('my-anchor-el');
                        }

                        if (el.step.stepId === '18') {
                            this.sharedService.learningBoardName$.next('');
                            document.querySelector('.learningMembers').classList.remove('my-anchor-el');
                            document.querySelector('.learningMembersAdd').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningMembersDialog') {
                            document.querySelector('.learningMembersDialog').dispatchEvent(event);
                        }
                        if (el.step.anchorId === 'learningFileuploadTask') {
                            document.querySelector('.learningFileupload').classList.remove('my-anchor-el');
                            document.querySelector('.learningFileuploadTask').dispatchEvent(event);
                        }
                        if (el.step.stepId === '22') {
                            this.sharedService.learningBoardName$.next('Вложение');
                        }
                        if (el.step.stepId === '23') {
                            document.querySelector('.learningAddLink').dispatchEvent(event);
                        }

                        if (el.step.stepId === '24') {
                            document.querySelector('.learningAddAttachment').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('');
                        }
                        if (el.step.anchorId === 'learningGroupTask') {

                            if (document.querySelector('.learningGroupAddShowTask')) {
                                document.querySelector('.learningGroupAddShowTask').dispatchEvent(event);
                                this.sharedService.learningBoardName$.next('Новая группа задач');
                            }
                            document.querySelector('.learningGroup').classList.remove('my-anchor-el');
                        }

                        if (el.step.anchorId === 'learningGroupAddDialog') {
                            document.querySelector('.learningGroupAddDialog').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('');
                        }
                        if (el.step.anchorId === 'learningTagTask') {
                            document.querySelector('.learningTagTask').dispatchEvent(event);
                            document.querySelector('.learningTag').classList.remove('my-anchor-el');
                        }
                        if (el.step.anchorId === 'learningTagDialog') {
                            document.querySelector('.learningTagAdd').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('Новая метка');
                        }
                        if (el.step.anchorId === 'learningTagAddDialog') {
                            document.querySelector('.learningTagAddDialog').dispatchEvent(event);
                            if (document.querySelector('.learningTagClose')) {
                                document.querySelector('.learningTagClose').dispatchEvent(event);
                            }
                            this.sharedService.learningBoardName$.next('');
                        }

                        if (el.step.anchorId === 'learningSaveTask') {
                            this.sharedService.learningBoardName$.next('save');
                        }
                    } else {

                        if (!!document.querySelector('.my-anchor-el')) {
                            document.querySelector('.my-anchor-el').classList.remove('my-anchor-el');
                        }

                        if (el.step.anchorId === 'tasksLearningBoardMenu') {
                            document.querySelector('.learningBoardsClose').dispatchEvent(event);
                        }

                        if (el.step.stepId === '3') {
                            document.querySelector('.learningCloseBoardCreate').dispatchEvent(event);
                        }
                        if (el.step.stepId === '5') {
                            if (this.boardsService.boardSelected$.value) {
                                this.boardId = this.boardsService.boardSelected$.value?.id;
                                this.fromBack = true;
                            }
                            document.querySelector('.learningBoardClose').dispatchEvent(event);
                            this.deleteBoard();
                            document.querySelector('.learningOpenCreateBoard').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('Новая доска');
                        }

                        if (el.step.anchorId === 'learningTaskCreate') {
                            if (this.boardsService.boardSelected$.value) {
                                this.nav.openBoardById(this.boardsService.boardSelected$.value.id);
                            }
                            document.querySelector('.tasksSecondStep').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningNameTask') {
                            document.querySelector('.learningCancelCreate').dispatchEvent(event);
                        }

                        if (el.step.stepId === '11') {
                            this.sharedService.learningBoardName$.next('delFirstItem');
                        }

                        if (el.step.stepId === '12') {
                            this.sharedService.learningBoardName$.next('delSecondItem');
                        }

                        if (el.step.stepId === '13') {
                            this.sharedService.learningBoardName$.next('delThirdItem');
                        }

                        if (el.step.stepId === '14') {
                            this.sharedService.learningSetSelected$.next(false);
                        }

                        if (el.step.anchorId === 'learningMembersDialog') {
                            document.querySelector('.learningMembersDialog').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningFileuploadTask') {
                            document.querySelector('.learningMembersAdd').dispatchEvent(event);
                        }

                        if (el.step.stepId === '22') {
                            document.querySelector('.learningCancelAttachment').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('');
                        }

                        if (el.step.stepId === '24') {
                            this.sharedService.learningBoardName$.next('Вложение');
                            document.querySelector('.learningDeleteLink').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningGroupTask') {
                            document.querySelector('.learningRepeatFileuploadTask').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('Вложение повтор');
                        }

                        if (el.step.anchorId === 'learningGroupDialog') {
                            document.querySelector('.learningGroupSelectClose').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningGroupAddDialog') {
                            document.querySelector('.learningGroupAddClose').dispatchEvent(event);
                        }

                        if (el.step.anchorId === 'learningTagDialog') {
                            document.querySelector('.learningTagClose').dispatchEvent(event);
                        }
                        if (el.step.anchorId === 'learningTagAddDialog') {
                            document.querySelector('.learningTagAddClose').dispatchEvent(event);
                            this.sharedService.learningBoardName$.next('');
                        }
                    }
                }, error => {
                    console.log(error);
                    this.sharedService.openTaskLearning$.next(false);
                });


        this.onboarding.currentScreen$.next(this.ONBOARDING_KEY);

        this.sharedService.openTaskLearning$
            .pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                if (val) {
                    this.isVisible = val;
                    this.startTour();
                }
            });


        this.auth.rights$.pipe(takeUntil(this.destroyed))
            .subscribe(val => {
                this.updateRights(val);
            });
    }

    startTour() {
        if (document.querySelector('.learningBoardsClose')) {
            document.querySelector('.learningBoardsClose').dispatchEvent(new Event('click'));
        }

        if (this.router.url.includes('/task/new')) {
            this.tasksService.taskHasChanges$.next(null);
            this.nav.closeTaskForm();
        }

        if (!this.router.url.endsWith('/boards')) {
            this.nav.closeTaskForm();
        }

        this.tourService.start();
    }

    closeLearningAfterError(evt) {
        setTimeout(() => {
            if (this.sharedService.openTaskLearning$.value && !document.querySelector('.learning-card') && !this.isPause) {
                this.closeFullScreen();
                this.notiService.handleError('Упс, что-то пошло не так, для прохождения обучения обновите страницу и запустите его снова.');
                this.tourService.end();
                this.openAnotherIntro(true);
            }
            }, 1500);
    }


    deleteBoard() {
        if (this.boardId) {
            this.tasksService.taskHasChanges$.next(null);
            this.nav.closeTaskForm();

            this.boardsService.boardHasChanges$.next(null);
            this.apiBoards.deleteBoard(this.boardId)
                .pipe(takeUntil(this.destroyed))
                .subscribe(board => {
                    this.boardsService.reloadBoard$.next(this.boardId);
                    const selected = this.boardsService.boardSelected$.getValue();
                    if (selected && selected.id === this.boardId) {
                        this.boardsService.selectDefaultBoard(this.auth.currentUser$.getValue());
                    }
                    this.notiService.handleSuccess(this.config.SUCCESS_BOARD_DELETE);
                    this.nav.closeBoardForm();
                    this.boardId = null;

                }, err => {
                    this.notiService.handleFullError(err);
                });
        }
    }

    openAnotherIntro(afterError = false) {
        if (!!document.querySelector('.my-anchor-el')) {
            if (document.querySelector('.learningMembers')) {
                document.querySelector('.learningMembers').classList.remove('my-anchor-el');
            }
            if (document.querySelector('.learningChecklist')) {
                document.querySelector('.learningChecklist').classList.remove('my-anchor-el');
            }
        }

        this.deleteBoard();

        this.sharedService.learningBoardName$.next('done');

        const title = afterError ? 'Повторить обучение' :'Пропустить обучение';
        let introSkip = IntroJs();
        introSkip.setOptions({
            steps: [
                {
                    element: document.querySelector('.skipOnboarding'),
                    intro: 'Вы можете вернуться к обучению в любой момент нажав на знак вопроса, находясь в разделе “Задачи”',
                    title: title,
                    position: 'right'
                }
            ],
            buttonClass: 'skip',
            highlightClass: 'skip',
            showStepNumbers: false,
            showBullets: false,
            skipLabel: null,
            hidePrev: true,
            doneLabel: 'Завершить'
        });

        introSkip.start();

        introSkip.oncomplete(el => {
            introSkip = null;
            this.sharedService.openTaskLearning$.next(false);
            this.sharedService.learningBoardName$.next('done');
            if (!!document.querySelector('.my-anchor-el')) {
                document.querySelector('.my-anchor-el').classList.remove('my-anchor-el');
            }
            this.isVisible = false;
        });
    }

    closeFullScreen() {
        this.compliteIntro = false;
        this.sharedService.openTaskLearning$.next(false);
        this.isVisible = false;
        this.onboarding.setViewed('tasks', true);
    }

    updateRights(rights) {
        this.isAdmin = rights?.isAdministrator;
        this.inited = true;
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.sharedService.learningBoardName$.next('');
        this.tourService.end();
        this.destroyed.complete();
    }
}
