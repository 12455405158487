import {JsonObject, JsonProperty, JsonType} from 'ta-json';


@JsonObject()
export class ApiTokenPayload {
    @JsonProperty()
    @JsonType(String)
    token: string;

    @JsonProperty('covered_token')
    @JsonType(String)
    coveredToken: string;
}

@JsonObject()
export class ApiTokenResponse {
    @JsonProperty()
    @JsonType(Number)
    count: number;

    @JsonProperty()
    @JsonType(Boolean)
    error: boolean;

    @JsonProperty()
    @JsonType(ApiTokenPayload)
    payload: Partial<ApiTokenPayload>;

    @JsonProperty()
    @JsonType(Number)
    status: number;

    @JsonProperty()
    @JsonType(String)
    timing: string;

    @JsonProperty()
    @JsonType(String)
    message: string;
}
