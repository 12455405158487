<section class="dialog">
    <header class="dialog__header">
        <h3 class="dialog__title">{{ title }}</h3>
        <mat-icon class="close" (click)="close()">close</mat-icon>
    </header>


    <div class="content kit">
        <form class="form"
              [formGroup]="formGroup"
              (ngSubmit)="onSubmit()">
            <label class="label kit _title">
                <span class="textarea-wrap">
                    <textarea [placeholder]="'Введите название'"
                              formControlName="title"
                              class="textarea _position-title"
                              [ngClass]="{'_error' : hasError(formGroup.controls['title'])}"
                              autocomplete="off"
                              #titleElem
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="0"
                              cdkAutosizeMaxRows="100"></textarea>
                    <span class="textarea-wrap__emul"></span>
                </span>
                <mat-error class="error" *ngIf="hasError(formGroup.controls['title'])">
                    {{ errorText('title')}}
                </mat-error>
            </label>

            <label class="label _file">
                <span class="label__txt">
                    Папка
                </span>
                <mat-select class="select"
                            formControlName="group_id"
                >
                    <mat-option [value]="-1">Без папки</mat-option>
                    <mat-option *ngFor="let grp of groups" [value]="grp.id">{{grp.title}}</mat-option>
                </mat-select>
            </label>
        </form>
    </div>


    <footer class="dialog__footer">
        <app-button class="button" type="reset" buttonType="outlined" title="Отменить" (click)="close()"></app-button>
        <app-button class="button" type="submit" (click)='onSubmit()' title="Сохранить"></app-button>
    </footer>

</section>
